<template>
  <div class="publishActivity">
    <div class="item">
      <div class="item-l must">活动名称：</div>
      <div class="item-r">
        <v-input
          v-model="form.activityName"
          placeholder="请输入活动名称"
          inputAlign="right"
        ></v-input>
      </div>
    </div>
    <div class="item" @click="showPlace">
      <div class="item-l must">活动地点：</div>
      <div class="item-r">
        <v-input
          disabled
          v-model="form.activityAddress"
          placeholder="请选择活动地点"
          inputAlign="right"
        ></v-input>
      </div>
    </div>
    <div class="item people">
      <div class="item-l must">活动人数：</div>
      <div class="item-r">
        <div class="stepper_box">
          <div class="left" @click="numChange('reduce')">
            <div class="line"></div>
          </div>
          <!-- <div class="center">{{ form.limitCount }}</div> -->
          <div class="center" contenteditable="true">
            <input
              onkeyup="this.value=this.value.replace(/\D|^0/g,'')"
              type="text"
              maxlength="4"
              v-model="form.limitCount"
            />
          </div>
          <div class="right" @click="numChange('add')">
            <div class="line1"></div>
            <div class="line2"></div>
          </div>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="item-l must">报名时间：</div>
      <div class="item-r"></div>
    </div>
    <div class="item">
      <div class="timeBox">
        <div class="left" @click="showStartTime">
          <span v-if="form.applyStartDate">{{ form.applyStartDate }}</span>
          <span v-else class="place">请选择报名开始时间</span>
        </div>
        <div>至</div>
        <div class="right" @click="showEndTime">
          <span v-if="form.applyEndDate">{{ form.applyEndDate }}</span>
          <span v-else class="place">请选择报名结束时间</span>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="item-l must">活动时间：</div>
      <div class="item-r"></div>
    </div>
    <div class="item">
      <div class="timeBox">
        <div class="left" @click="showActiStartTime">
          <span v-if="form.startDate">{{ form.startDate }}</span>
          <span v-else class="place">请选择活动开始时间</span>
        </div>
        <div>至</div>
        <div class="right" @click="showActiEndTime">
          <span v-if="form.endDate">{{ form.endDate }}</span>
          <span v-else class="place">请选择活动结束时间</span>
        </div>
      </div>
    </div>
    <div class="oItem">
      <div class="item-l must">封面图片：</div>
      <div class="item-r">
        <div class="upload">
          <v-upload
            :imgUrl.sync="form.activityPicture1"
            :activityPicture.sync="form.activityPicture"
            ref="load1"
            :maxCount="1"
          ></v-upload>
        </div>
      </div>
    </div>
    <div class="oItem">
      <div class="item-l must">活动图片：</div>
      <div class="item-r">
        <div class="upload">
          <v-upload
            :imgUrl.sync="form.imageUrls1"
            :activityPicture.sync="form.imageUrls"
            ref="load2"
            :maxCount="1"
          ></v-upload>
        </div>
      </div>
    </div>
    <div class="oItem">
      <div class="item-l must">活动文本：</div>
      <div class="item-r">
        <v-input
          v-model="form.content"
          placeholder="请填写活动文本"
          type="textarea"
        ></v-input>
      </div>
    </div>
    <div class="submit" @click="publishActivityUrl">发布活动</div>
    <!-- <v-calendar
      v-model="show"
      type="range"
      :maxDate="maxDate"
      @updateValue="updateValue"
      @change="change"
    ></v-calendar>
    <v-calendar
      v-model="actiShow"
      type="range"
      :minDate="minDate"
      @updateValue="updateActiShowValue"
      @change="changeActiShow"
    ></v-calendar> -->
    <v-dateTimePicker
      type="datetime"
      :value="form.applyStartDate | dateFormat"
      :minDate="new Date()"
      :maxDate="
        (form.applyEndDate || form.startDate || form.endDate) | tenYearsLater
      "
      :valueShow.sync="showStart"
      :isAuto="true"
      @confirmPicker="confirmStart"
    ></v-dateTimePicker>
    <v-dateTimePicker
      type="datetime"
      :value="form.applyEndDate | dateFormat"
      :valueShow.sync="showEnd"
      :isAuto="true"
      :minDate="form.applyStartDate | dateFormat"
      :maxDate="(form.startDate || form.endDate) | tenYearsLater"
      @confirmPicker="confirmEnd"
    ></v-dateTimePicker>
    <v-dateTimePicker
      type="datetime"
      :value="form.startDate | dateFormat"
      :valueShow.sync="actiStartShow"
      :isAuto="true"
      :minDate="(form.applyEndDate || form.applyStartDate) | dateFormat"
      :maxDate="form.endDate | tenYearsLater"
      @confirmPicker="confirmActiStart"
    ></v-dateTimePicker>
    <v-dateTimePicker
      type="datetime"
      :value="form.endDate | dateFormat"
      :minDate="
        (form.startDate || form.applyEndDate || form.applyStartDate)
          | dateFormat
      "
      :isAuto="true"
      :valueShow.sync="actiEndShow"
      @confirmPicker="confirmActiEnd"
    ></v-dateTimePicker>
    <v-picker
      :columns="areaList"
      :valueShow="pickShow"
      :valueKey="valueKey"
      @clickOverlay="clickPickOverlay"
      @cancel="cancelPick"
      @confirmPicker="confirmPick"
    ></v-picker>
  </div>
</template>

<script>
import { publishActivityUrl, areaListUrl } from "./api.js";
import { toRegister } from "@/utils/common.js";

var moment = require("moment");
export default {
  name: "postActivity",
  data() {
    return {
      valueKey: "placeName",
      pickShow: false,
      showStart: false,
      showEnd: false,
      actiStartShow: false,
      actiEndShow: false,
      areaList: [],
      form: {
        placeId: "",
        content: "",
        activityPicture: "",
        imageUrls: "",
        limitCount: 1,
        activityName: "",
        activityAddress: "",
        appliedTime: "请选择报名时间",
        actiDate: "请选择活动时间",
        applyStartDate: "",
        applyEndDate: "",
        endDate: "",
        startDate: "",
      },
    };
  },
  filters: {
    dateFormat: function (value) {
      if (value) {
        return moment(value).toDate();
      }
      return new Date();
    },
    tenYearsLater: function (value) {
      if (value) {
        return moment(value).toDate();
      }
      return moment().add("10", "y").toDate();
    },
  },
  destroyed() {},
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    maxDate() {
      // 报名结束的结束时间不能大于该时间
      const time =
        this.form.applyEndDate || this.form.startDate || this.form.endDate;
      const dateFormat = time ? moment(time).toDate() : null;
      return dateFormat;
    },
    minDate() {
      // 活动开始的起始时间不能小于该时间
      const time =
        this.form.startDate ||
        this.form.applyEndDate ||
        this.form.applyStartDate;
      const dateFormat = time ? moment(time).toDate() : null;
      return dateFormat;
    },
  },
  async mounted() {
    await this.getAreaList();
  },
  methods: {
    numChange(type) {
      if (type == "add") {
        if (this.form.limitCount < 9999) {
          this.form.limitCount++;
        } else {
          this.form.limitCount = 9999;
        }
      } else if (type == "reduce") {
        if (this.form.limitCount == 1) {
          this.form.limitCount == 1;
        } else {
          this.form.limitCount--;
        }
      }
    },
    showPlace() {
      this.pickShow = true;
    },
    clickPickOverlay() {
      this.pickShow = false;
    },
    cancelPick() {
      this.pickShow = false;
    },
    confirmPick(oValue) {
      console.log(oValue, 122);
      this.pickShow = false;
      this.form.placeId = oValue.placeId;
      this.form.activityAddress = oValue.placeName;
    },
    /* start-时间选择器 */
    // change(value) {
    //   this.form.applyStartDate = moment(value[0]).format("YYYY-MM-DD");
    //   this.form.applyEndDate = moment(value[1]).format("YYYY-MM-DD");
    //   this.form.appliedTime =
    //     this.form.applyStartDate + " ~ " + this.form.applyEndDate;
    // },
    // changeActiShow(value) {
    //   this.form.startDate = moment(value[0]).format("YYYY-MM-DD");
    //   this.form.endDate = moment(value[1]).format("YYYY-MM-DD");
    //   this.form.actiDate = this.form.startDate + " ~ " + this.form.endDate;
    // },
    showStartTime() {
      this.showStart = true;
    },
    showEndTime() {
      this.showEnd = true;
    },
    showActiStartTime() {
      this.actiStartShow = true;
    },
    showActiEndTime() {
      this.actiEndShow = true;
    },
    confirmStart(val) {
      this.form.applyStartDate = moment(val).format("YYYY-MM-DD HH:mm");
    },
    confirmEnd(val) {
      this.form.applyEndDate = moment(val).format("YYYY-MM-DD HH:mm");
    },
    confirmActiStart(val) {
      this.form.startDate = moment(val).format("YYYY-MM-DD HH:mm");
    },
    confirmActiEnd(val) {
      this.form.endDate = moment(val).format("YYYY-MM-DD HH:mm");
    },
    async getAreaList() {
      let params = {
        tenantId: this.tenantId,
        houseId: this.roomId || this.communityId || undefined,
      };
      let res = await this.$axios.get(
        `${areaListUrl}`,
        { params },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (res.code === 200) {
        this.areaList = res.data;
      }
    },
    /** end */
    publishActivityUrl() {
      let _this = this;
      if (!this.form.activityName) {
        this.$toast("请填写活动名称");
        return;
      }
      if (!this.form.activityAddress) {
        this.$toast("请填写活动地点");
        return;
      }
      if (!this.form.limitCount) {
        this.$toast("请填写活动人数");
        return;
      }
      if (!this.form.applyStartDate && !this.form.applyEndDate) {
        this.$toast("请选择报名时间");
        return;
      }
      if (!this.form.startDate && !this.form.endDate) {
        this.$toast("请选择活动时间");
        return;
      }
      if (!this.form.activityPicture) {
        this.$toast("请上传封面图片");
        return;
      }
      if (!this.form.imageUrls) {
        this.$toast("请上传活动图片");
        return;
      }
      if (!this.form.content) {
        this.$toast("请上传活动文本");
        return;
      }
      // if (this.$refs.load1.status == 3) {
      //   this.$toast("图片正在上传中");
      //   return;
      // }
      // if (this.$refs.load2.status == 3) {
      //   this.$toast("图片正在上传中");
      //   return;
      // }
      let params = {
        activityCategory: 1, // 写死为热门活动
        activityName: this.form.activityName,
        activityAddress: this.form.activityAddress,
        publisherId: this.userId,
        communityId: this.communityId,
        activityType: 0,
        limitCount: _this.form.limitCount,
        perLimitCount: Number(_this.form.limitCount),
        applyStartDate: _this.form.applyStartDate,
        applyEndDate: _this.form.applyEndDate,
        startDate: _this.form.startDate,
        endDate: _this.form.endDate,
        isLimit: "2",
        scheduleList: [
          {
            limitCount: _this.form.limitCount,
            perLimitCount: _this.form.limitCount,
            applyStartDate: _this.form.applyStartDate,
            applyEndDate: _this.form.applyEndDate,
            startDate: _this.form.startDate,
            endDate: _this.form.endDate,
            isLimit: "2",
          },
        ],
        imageUrls: this.form.imageUrls,
        activityPicture: this.form.activityPicture,
        content: this.form.content,
      };
      this.$axios
        .post(`${publishActivityUrl}`, params, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          if (res.code === 200) {
            this.$toast({
              message: `发布成功`,
              duration: 2000,
            });
            this.$router.replace({
              name: "myPublishActivity",
            });
          } else {
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.publishActivity {
  min-height: 100vh;
  // padding: 40px 40px 160px;
  padding: 0px 0 160px 40px;
  box-sizing: border-box;

  ::v-deep .van-field {
    min-height: 100%;
    padding: 0;
    font-size: 32px;

    .van-field__body {
      min-height: 100%;

      input {
        /** start 设置行高与高度一致以保证输入框提示文字居中显示 */
        height: 48px;
        line-height: 48px;
        /** end */
        min-height: 100%;
      }
    }

    .van-field__control:disabled {
      // color: #000;
      // -webkit-text-fill-color: #000;
    }
    textarea {
      border-bottom: 2px solid #f4f4f4;
      height: 300px !important;
    }
  }

  .item {
    display: flex;
    padding-top: 34px;
    padding-bottom: 24px;
    border-bottom: 2px solid #f4f4f4;
    box-sizing: border-box;
    .timeBox {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      .left,
      .right {
        flex: 1;
        text-align: center;
        font-size: 32px;
        color: #666;
        .place {
          color: #c8c9cc;
        }
      }
    }
    .item-l {
      width: 160px;
      font-size: 32px;
      position: relative;
      white-space: nowrap;
    }

    .must::before {
      box-sizing: border-box;
      height: 100%;
      width: 45px;
      font-size: 32px;
      content: "*";
      position: absolute;
      left: -40px;
      color: #ff602a;
      top: 0;
      bottom: 0;
      margin: auto;
      padding-right: 10px;
      text-align: right;
    }

    .item-r {
      display: flex;
      flex: 1;
      padding-left: 16px;
      font-size: 32px;
      color: #666;
      margin-right: 20px;
      text-align: right;

      .stepper_box {
        display: flex;
        justify-content: flex-end;

        .center {
          min-width: 48px;
          max-width: 80px;
          height: 48px;
          background: rgba(0, 0, 0, 0.05);
          border-radius: 10px;
          line-height: 48px;
          text-align: center;
          margin: 0 8px;
          display: inline-block;
          input {
            display: inline-block;
            background: rgba(0, 0, 0, 0.05);
            min-width: 48px;
            max-width: 80px;
            height: 100%;
            text-align: center;
          }
        }

        .right {
          width: 48px;
          height: 48px;
          background: #307cff;
          border-radius: 10px;
          box-sizing: border-box;
          position: relative;

          .line1 {
            position: absolute;
            width: 32px;
            height: 6px;
            background: #fff;
            border-radius: 3px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }

          .line2 {
            position: absolute;
            width: 6px;
            height: 32px;
            background: #fff;
            border-radius: 3px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }

        .left {
          width: 48px;
          height: 48px;
          border-radius: 10px;
          border: 2px solid #307cff;
          box-sizing: border-box;
          position: relative;

          .line {
            position: absolute;
            width: 32px;
            height: 6px;
            background: #307cff;
            border-radius: 3px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      span {
        width: 100%;
        display: inline-block;

        &.place {
          color: #c8c9cc;
        }
      }

      > div {
        width: 100%;
      }
    }
  }

  .people {
    .item-r {
      border: none;
    }
  }

  .oItem {
    margin: 50px 0;
    display: flex;
    margin-bottom: 100px;

    .item-l {
      width: 160px;
      position: relative;
      font-size: 32px;
      height: 60px;
      line-height: 60px;
      position: relative;
      white-space: nowrap;
    }

    .must::before {
      box-sizing: border-box;
      // height: 30px;
      height: 100%;
      width: 40px;
      font-size: 32px;
      content: "*";
      position: absolute;
      left: -40px;
      color: red;
      top: 0;
      margin: auto;
      padding-right: 10px;
      text-align: right;
    }

    .item-r {
      padding-left: 16px;
      flex: 1;
    }
  }

  .submit {
    width: 550px;
    height: 66px;
    background: linear-gradient(180deg, #73a8ff 0%, #307cff 100%);
    border-radius: 10px;
    font-size: 30px;
    line-height: 66px;
    text-align: center;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 40px;
    margin: auto;
    color: #fff;
    font-weight: 600;
  }
}
</style>
